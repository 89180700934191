import '../../css/app/components/splash.scss';
function hideSplash() {
    var splash = document.getElementById('splash');
    if (splash) {
        splash.style.opacity = 0;
        setTimeout(function () {
            splash.style.display = 'none'
        }, 500);
    }
}

setTimeout(function () {
    hideSplash();
}, 2500);